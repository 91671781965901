import React from "react"
import { Box } from "@chakra-ui/core"
import qs from "query-string"
import Layout from "../components/layout"
import {Bold, Paragraph, SectionHeading} from "../components/ui-elements"
import SEO from "../components/seo"
import {Link as GatsbyLink} from "gatsby";
import VirtualAssistant from "./virtual-assistant"

const SmartValidator = ({ location }) => {
  const { hash } = qs.parse(location.search)
  const [loaded, setLoaded] = React.useState(false)

  React.useEffect(() => {
    setLoaded(true)
  }, [])

  if (!loaded) return null

  return (
    <Layout>
      <SEO title="Smart Validator - VERIFiSMART" description="Verify your registered work with VERIFiSMART Smart Validator - the secure way to confirm the authenticity and ownership of your creations."/>
      <Box py="16">
        <Box color="theme-dark" textAlign='center' maxW="containers.xl" mx="auto" my="16" w="100%">
          <SectionHeading>
            Verify registered work with Smart Validator&#8482;
          </SectionHeading>
          <Box
            mt="16"
            mx="auto"
            as="iframe"
            w="100%"
            maxW="containers.xl"
            h="600px"
            src={`${process.env.GATSBY_APP_URL}/embed/validator?${
              hash ? `hash=${hash}` : ""
            }`}
          />
          <Paragraph>
            We focus on helping you protect your intellectual property.
            To understand more about intellectual property, please visit our{" "}
            <Bold
                textDecoration="underline"
                to="/what-is-ip"
                as={GatsbyLink}
            >What Is IP
            </Bold> page.
          </Paragraph>
        </Box>
      </Box>
      <VirtualAssistant />
    </Layout>
  )
}

export default SmartValidator
